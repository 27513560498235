import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { InvestmentsListStatusTypes } from '../../useInvestmentsList.model';
import { getEnhancedInvestments, getTotals } from './util';

/**
 * This util attempts to structure the data in a more usable format
 * than what is received from the api. This is because the structure
 * that we receive is drastically different than what we need on the UI.
 */
export const formatData = (data, managementStrategy) => {
  var _data$investmentBySta, _data$investmentBySta2, _data$investmentBySta3, _data$investmentBySta4, _data$investmentBySta5, _data$investmentBySta6, _data$investmentBySta7, _data$investmentBySta8, _data$investmentBySta9, _data$investmentBySta10, _data$lastCommentary;
  if (!data) {
    return {};
  }
  const active = (_data$investmentBySta = data === null || data === void 0 ? void 0 : (_data$investmentBySta2 = data.investmentByStatus) === null || _data$investmentBySta2 === void 0 ? void 0 : _data$investmentBySta2.ACTIVE) !== null && _data$investmentBySta !== void 0 ? _data$investmentBySta : {};
  const matured = (_data$investmentBySta3 = data === null || data === void 0 ? void 0 : (_data$investmentBySta4 = data.investmentByStatus) === null || _data$investmentBySta4 === void 0 ? void 0 : _data$investmentBySta4.MATURED) !== null && _data$investmentBySta3 !== void 0 ? _data$investmentBySta3 : {};
  const pending = (_data$investmentBySta5 = data === null || data === void 0 ? void 0 : (_data$investmentBySta6 = data.investmentByStatus) === null || _data$investmentBySta6 === void 0 ? void 0 : _data$investmentBySta6.PENDING) !== null && _data$investmentBySta5 !== void 0 ? _data$investmentBySta5 : {};
  const commitmentPending = (_data$investmentBySta7 = data === null || data === void 0 ? void 0 : (_data$investmentBySta8 = data.investmentByStatus) === null || _data$investmentBySta8 === void 0 ? void 0 : _data$investmentBySta8.COMMITMENT_PENDING) !== null && _data$investmentBySta7 !== void 0 ? _data$investmentBySta7 : {};
  const commitmentActive = (_data$investmentBySta9 = data === null || data === void 0 ? void 0 : (_data$investmentBySta10 = data.investmentByStatus) === null || _data$investmentBySta10 === void 0 ? void 0 : _data$investmentBySta10.COMMITMENT_ACTIVE) !== null && _data$investmentBySta9 !== void 0 ? _data$investmentBySta9 : {};
  const investmentsGates = _objectSpread({}, pending.gates);

  /**
   * This is used to add extra properties such as
   * urls and mapping performance status labels.
   */
  const {
    activeInvestments,
    maturedInvestments,
    pendingInvestments,
    pendingCommitments,
    activeCommitments
  } = getEnhancedInvestments({
    active: active.investments,
    matured: matured.investments,
    pending: pending.investments,
    commitmentPending: commitmentPending.commitments,
    commitmentActive: commitmentActive.commitments,
    investmentsGates,
    managementStrategy
  });

  /**
   * This is required because we receive two separate lists
   * for active and pending, while we need to show a combined
   * list and summary values.
   */
  const totals = getTotals({
    active,
    matured,
    pending,
    commitmentPending,
    commitmentActive
  });
  const activeCommitmentsFiltered = (activeCommitments === null || activeCommitments === void 0 ? void 0 : activeCommitments.filter(commitment => !activeInvestments.find(investment => investment.noteUrlHash === commitment.noteUrlHash))) || [];
  const pendingCommitmentsFiltered = (pendingCommitments === null || pendingCommitments === void 0 ? void 0 : pendingCommitments.filter(commitment => !pendingInvestments.find(investment => investment.noteUrlHash === commitment.noteUrlHash))) || [];
  return {
    investmentsGates,
    investments: {
      [InvestmentsListStatusTypes.Current]: [...activeInvestments, ...pendingInvestments, ...pendingCommitmentsFiltered, ...activeCommitmentsFiltered],
      [InvestmentsListStatusTypes.Past]: [...maturedInvestments]
    },
    lastCommentary: _objectSpread({}, (_data$lastCommentary = data.lastCommentary) === null || _data$lastCommentary === void 0 ? void 0 : _data$lastCommentary.offerings),
    totals
  };
};